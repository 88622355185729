import { ReactNode } from "react";

export const highlightText = (
  text?: string,
  search?: string,
): string | ReactNode => {
  if (!text) return "";
  if (!search) return text;
  if (!text.toLowerCase().includes(search.toLowerCase())) return text;

  const start = text.toLowerCase().indexOf(search.toLowerCase());
  const end = start + search.length;
  const highlight = text.substring(start, end);
  return (
    <>
      {text.substring(0, start)}
      <span className="rounded bg-mint bg-opacity-50">{highlight}</span>
      {text.substring(end, text.length)}
    </>
  );
};
