import cubejs from "@cubejs-client/core";
import { useSelector } from "react-redux";

import { selectCubeToken } from "app/cubeTokenSlice";
import { isMockedAPI } from "config";

declare global {
  interface Window {
    Cypress?: any;
  }
}

/**
 * useCubejsApi
 * returns usable cubeApi object ready to interact with.
 * @param {string} marker - optional header used to identify request while testing.
 */
export const useCubejsApi = (marker: string = "real-data") => {
  const cubeToken = useSelector(selectCubeToken);
  if (marker !== "real-data" && (isMockedAPI() || !!window.Cypress)) {
    return cubejs(cubeToken?.token || "", {
      apiUrl: cubeToken?.restApiUrl || "fake",
      headers: { marker },
    });
  }
  return cubejs(cubeToken?.token || "", {
    apiUrl: cubeToken?.restApiUrl || "fake",
  });
};
