import {
  fetchBaseQuery,
  FetchBaseQueryArgs,
} from "@reduxjs/toolkit/query/react";
import env from "config";

const prepareBaseQuery = ({
  baseUrl,
  ...baseFetchOptions
}: FetchBaseQueryArgs) =>
  fetchBaseQuery({
    baseUrl: `${env("REACT_APP_API_URL")}${baseUrl}`,
    credentials: "include",
    mode: "cors",
    cache: "no-cache",
    ...baseFetchOptions,
  });

export default prepareBaseQuery;
