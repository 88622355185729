import {
  Duration,
  FormatDistanceToken,
  formatDuration,
  hoursToMilliseconds,
  intervalToDuration,
  millisecondsToHours,
  millisecondsToMinutes,
} from "date-fns";
import { filter, head, indexOf, propOr } from "ramda";

const formatKeys: (keyof Duration)[] = [
  "years",
  "months",
  "weeks",
  "days",
  "hours",
  "minutes",
  "seconds",
];

const formatDistanceLocale: Record<FormatDistanceToken, string> = {
  xYears: "{{count}}y",
  xMonths: "{{count}}mo",
  xDays: "{{count}}d",
  xWeeks: "{{count}}w",
  xSeconds: "{{count}}s",
  xMinutes: "{{count}}m",
  xHours: "{{count}}h",
  lessThanXSeconds: "<{{count}}s",
  halfAMinute: "30s",
  lessThanXMinutes: "<{{count}}m",
  aboutXHours: "~{{count}}h",
  aboutXWeeks: "~{{count}}w",
  aboutXYears: "~{{count}}y",
  aboutXMonths: "~{{count}}y",
  overXYears: ">{{count}}y",
  almostXYears: "almost {{count}}y",
};

const partialFormatDuration = (
  duration: Duration,
  format: (keyof Duration)[] = formatKeys,
) =>
  formatDuration(duration, {
    format,
    locale: {
      formatDistance: (token: FormatDistanceToken, count: number) =>
        formatDistanceLocale[token].replace("{{count}}", count.toString()),
    },
  });

const formatSeconds = (seconds: number, forceshh?: boolean): string => {
  if (!seconds) return "-";

  const milliseconds = seconds * 1000;
  let hours = millisecondsToHours(milliseconds);
  let minutes =
    Math.round(
      millisecondsToMinutes(milliseconds - hoursToMilliseconds(hours)) / 10,
    ) * 10;
  if (minutes === 60) {
    hours += 1;
    minutes = 0;
  }

  if (!!forceshh && (hours > 0 || minutes > 50)) {
    if (hours > 20) return `~${hours}h`;
    else if (hours > 0 && minutes > 0) return `${hours}h ${minutes}m`;
    else if (hours > 0) return `${hours}h`;
    return `${minutes}m`;
  }

  const duration = intervalToDuration({ start: 0, end: milliseconds });
  const fragments = filter(
    (key) => (propOr(0, key, duration) as number) > 0,
    formatKeys,
  );
  const headIndex = indexOf(head(fragments), formatKeys);

  if (fragments.length === 1) {
    // only singular `days`, `minutes`, `hours` etc found
    return partialFormatDuration(duration);
  } else {
    // take into account the first value found and the adjacent one on format array
    return partialFormatDuration(
      duration,
      formatKeys.slice(headIndex, headIndex + 2),
    );
  }
};

export default formatSeconds;
