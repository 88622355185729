import { useCubeQuery } from "@cubejs-client/react";
import { format, subDays } from "date-fns";
import { useGetUserQuery } from "features/profile/profileApi";
import { useCubejsApi } from "utils/hooks/useCubejsApi";

const useUnresponsiveContactEmails = ({
  id,
  skip,
}: {
  id: string;
  skip: boolean;
}) => {
  const cubeApi = useCubejsApi("unresponsive-contact-emails");
  const afterDate = format(subDays(new Date(), 90), "yyyy-MM-dd");
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();
  const { resultSet, isLoading: isReportLoading } = useCubeQuery(
    {
      measures: [],
      dimensions: ["dim_email.subject", "dim_email.message_sent_date_time"],
      filters: [
        {
          member: "fact_email.is_inbound",
          operator: "equals",
          values: ["false"],
        },
        {
          member: "dim_user.email",
          operator: "equals",
          values: [user ? (user.email as string) : ""],
        },
        {
          member: "dim_contact.uuid",
          operator: "equals",
          values: [id],
        },
        {
          member: "dim_email.message_sent_date_time",
          operator: "afterDate",
          values: [afterDate],
        },
      ],
    },
    {
      cubeApi,
      skip,
    },
  );
  const results = (resultSet?.tablePivot() || []) as any[];

  return {
    resultSet: results,
    isLoading: isReportLoading || isUserLoading,
  };
};

export default useUnresponsiveContactEmails;
