import { Filter, TQueryOrderArray } from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";
import { useCubejsApi } from "utils/hooks/useCubejsApi";

const useTopicsBreakdown = ({
  orderOption,
  typeFilter,
  search = "",
  limit = 100,
}: {
  orderOption: string;
  typeFilter: string;
  search?: string;
  limit?: number;
}) => {
  const cubeApi = useCubejsApi("topics-breakdown");
  const getOrder = (): TQueryOrderArray => {
    switch (orderOption) {
      case "most-interest":
        return [["fact_topic_scores.interest_current", "desc"]];

      case "most-email":
        return [["fact_topic_scores.email_count_current", "desc"]];

      case "largest-interest-gain":
        return [["fact_topic_scores.interest_delta", "desc"]];

      case "largest-interest-drop":
        return [["fact_topic_scores.interest_delta", "asc"]];

      default:
        return [["fact_topic_scores.interest_current", "desc"]];
    }
  };
  const { resultSet, isLoading: isReportLoading } = useCubeQuery(
    {
      measures: [
        "fact_topic_scores.interest_current",
        "fact_topic_scores.interest_delta",
        "fact_topic_scores.email_count_current",
        "fact_topic_scores.email_count_delta",
      ],
      order: getOrder(),
      dimensions: ["dim_topic.id", "dim_topic.topic", "dim_topic.topic_type"],
      filters: [
        {
          member: "dim_topic.id",
          operator: "set",
        },
        ...(typeFilter !== "all"
          ? [
              {
                member: "dim_topic.topic_type",
                operator: "equals",
                values: [typeFilter],
              },
            ]
          : []),
        ...(search !== ""
          ? [
              {
                member: "dim_topic.topic",
                operator: "contains",
                values: [search],
              },
            ]
          : []),
      ] as Filter[],
      limit,
    },
    {
      cubeApi,
    },
  );
  const results = (resultSet?.tablePivot() || []) as any[];
  return {
    resultSet: results,
    isLoading: isReportLoading,
  };
};

export default useTopicsBreakdown;
