import { Query } from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";
import { useCubejsApi } from "utils/hooks/useCubejsApi";
import { IRelationshipSignal } from ".";
import { RelationshipSignalType } from "./useRelationshipSignals";
import {
  IContactDetail,
  ICompanyDetail,
  ActivityType,
} from "features/entity/entity.interfaces";
import { head } from "ramda";
import { maxBy } from "lodash";

const USOLICITED_COMPANY_THRESHOLD = 10;
const USOLICITED_CONTACT_THRESHOLD = 5;
const LINK_CLICKS_COMPANY_THRESHOLD = 20;
const LINK_CLICKS_CONTACT_THRESHOLD = 10;
const REVISITS_COMPANY_THRESHOLD = 20;
const REVISITS_CONTACT_THRESHOLD = 10;

const getRelationshipSignalLabel = (
  input: number,
  type: RelationshipSignalType,
  contact?: IContactDetail,
  company?: ICompanyDetail,
  teamName?: string,
) => {
  const entityName = company?.displayName || contact?.fullName.split(" ")[0];

  switch (type) {
    case "relationship-score":
      if (input > 0) {
        return `The company relationship score with ${entityName} has increased over time`;
      }
      if (input < 0) {
        return `The company relationship score with ${entityName} has decreased over time`;
      }
      return `The company relationship score with ${entityName} has remained constant over time`;
    case "personal-relationship-score":
      if (input > 0) {
        return `Your personal relationship score with ${entityName} has increased over time`;
      }
      if (input < 0) {
        return `Your personal relationship score with ${entityName} has decreased over time`;
      }
      return `Your personal relationship score with ${entityName} has remained constant over time`;
    case "interactive-colleagues":
      if (input === 1) {
        return `There is only 1 colleague interacting with ${entityName}`;
      }
      return `There are more than 2 colleagues interacting with ${entityName}`;
    case "unsolicited-inbound":
      return !!company
        ? `Contacts at this company ${
            input > USOLICITED_COMPANY_THRESHOLD ? "regularly" : "rarely"
          } start a conversation`
        : `${entityName} ${
            input > USOLICITED_CONTACT_THRESHOLD ? "regularly" : "rarely"
          } starts a conversation`;
    case "engagement-score":
      if (input > 0) {
        return !!company
          ? `Contacts at ${entityName} have shown a consistent or increasing number of emails and meetings overall`
          : `${entityName} has shown a consistent or increasing number of emails and meetings overall`;
      }
      return !!company
        ? `Contacts at ${entityName} have shown a decreasing number of emails and meetings overall`
        : `${entityName} has shown a decreasing number of emails and meetings overall`;
    case "teams-engagement-score":
      if (input > 0) {
        return !!company
          ? `Contacts at ${entityName} have shown a consistent or increasing number of emails and meetings with ${teamName}`
          : `${entityName} has shown a consistent or increasing number of emails and meetings with ${teamName}`;
      }
      return !!company
        ? `Contacts at ${entityName} have shown a decreasing number of emails and meetings with ${teamName}`
        : `${entityName} has shown a decreasing number of emails and meetings with ${teamName}`;

    case "response-time":
      if (input < 0) {
        return !!company
          ? `Contacts at ${entityName} are responding to emails faster than before`
          : `${entityName} is responding to emails faster than before`;
      }
      if (input > 0) {
        return !!company
          ? `Contacts at ${entityName} are responding to emails slower than before`
          : `${entityName} is responding to emails slower than before`;
      }
      return !!company
        ? `Contacts at ${entityName} are responding to emails consistently`
        : `${entityName} is responding to emails consistently`;
    case "link-clicks":
      return !!company
        ? `Contacts at ${entityName} ${
            input > LINK_CLICKS_COMPANY_THRESHOLD ? "often" : "rarely"
          } click the links in emails that you send`
        : `${entityName} ${
            input > LINK_CLICKS_CONTACT_THRESHOLD ? "often" : "rarely"
          } clicks the links in emails that you send`;
    case "revisits":
      return !!company
        ? `Contacts at ${entityName} often revisit the emails that you send`
        : `${entityName} often revisits the emails that you send`;
    case "active-contacts":
      return `Over 50% of the contacts at this company are ${
        input > 0.5 ? "active" : "inactive"
      }`;
    case "active-contacts-change":
      if (input > 0) {
        return `There has been an increase in active contacts at ${entityName}`;
      }
      return `There has been a decrease in active contacts at ${entityName}`;
    case "new-contacts":
      return input === 1
        ? `There is a new contact at ${entityName}`
        : `There are ${input} new contacts at ${entityName}`;
  }
};

export const applyRelationshipSignalLogic = (
  type: RelationshipSignalType,
  result: { [key: string]: string | number | boolean | undefined }[],
  contact?: IContactDetail,
  company?: ICompanyDetail,
): IRelationshipSignal[] => {
  if (result.length === 0) {
    return [];
  }
  const row = head(result) as { [key: string]: number };

  switch (type) {
    case "relationship-score":
      const relationshipScoreDelta =
        +row["fact_table.relationship_score_current"] -
        +row["fact_table.relationship_score_prev"];
      return [
        {
          positive: relationshipScoreDelta >= 0,
          label: getRelationshipSignalLabel(
            relationshipScoreDelta,
            type,
            contact,
            company,
          ),
        },
      ];
    case "personal-relationship-score":
      const personalRelationshipScoreDelta =
        +row["fact_table.relationship_score_current"] -
        +row["fact_table.relationship_score_prev"];
      return [
        {
          positive: personalRelationshipScoreDelta >= 0,
          label: getRelationshipSignalLabel(
            personalRelationshipScoreDelta,
            type,
            contact,
            company,
          ),
        },
      ];
    case "interactive-colleagues":
      const interactiveColleagues =
        +row["fact_relationship_score.colleague_count"];
      if (interactiveColleagues === 0 || interactiveColleagues === 2) {
        return [];
      }
      return [
        {
          positive: interactiveColleagues > 2,
          label: getRelationshipSignalLabel(
            interactiveColleagues,
            type,
            contact,
            company,
          ),
        },
      ];
    case "unsolicited-inbound":
      const unsolicitedInbound = +row["fact_table.unsolicited_inbound_current"];
      return [
        {
          positive:
            unsolicitedInbound >
            (!!company
              ? USOLICITED_COMPANY_THRESHOLD
              : USOLICITED_CONTACT_THRESHOLD),
          label: getRelationshipSignalLabel(
            unsolicitedInbound,
            type,
            contact,
            company,
          ),
        },
      ];
    case "engagement-score":
      const engagementScoreDelta =
        +row["fact_table.all_interactions_current"] -
        +row["fact_table.all_interactions_prev"];
      return engagementScoreDelta === 0
        ? []
        : [
            {
              positive: engagementScoreDelta > 0,
              label: getRelationshipSignalLabel(
                engagementScoreDelta,
                type,
                contact,
                company,
              ),
            },
          ];
    case "teams-engagement-score":
      const teamsEngagementScoreDelta = result.map((el) => ({
        count:
          +(el["fact_table.all_interactions_current"] as string) -
          +(el["fact_table.all_interactions_prev"] as string),
        teamName: el["dim_team.name"] as string,
      }));

      const bestTeamEngagementIncrease = maxBy(
        teamsEngagementScoreDelta.filter(({ count }) => count > 0),
        ({ count }) => count,
      )?.teamName;

      const negativeTeams = teamsEngagementScoreDelta.filter(
        ({ count }) => count < 0,
      );
      const signals = [];

      if (bestTeamEngagementIncrease !== undefined) {
        signals.push({
          positive: true,
          label: getRelationshipSignalLabel(
            1,
            type,
            contact,
            company,
            bestTeamEngagementIncrease,
          ),
        });
      }
      if (negativeTeams.length < 3) {
        signals.push(
          ...negativeTeams.map(({ teamName }) => ({
            positive: false,
            label: getRelationshipSignalLabel(
              -1,
              type,
              contact,
              company,
              teamName,
            ),
          })),
        );
      } else {
        signals.push({
          positive: false,
          label: getRelationshipSignalLabel(
            -1,
            type,
            contact,
            company,
            "multiple teams",
          ),
        });
      }
      return signals;

    case "response-time":
      const responseTimeDelta =
        +row["fact_table.reply_response_time_current"] -
        +row["fact_table.reply_response_time_prev"];
      return [
        {
          positive: responseTimeDelta <= 0,
          label: getRelationshipSignalLabel(
            responseTimeDelta,
            type,
            contact,
            company,
          ),
        },
      ];
    case "link-clicks":
      const linkClicksDelta =
        +row["fact_table.link_clicks_current"] -
        +row["fact_table.link_clicks_prev"];

      return [
        {
          positive:
            linkClicksDelta >
            (!!company
              ? LINK_CLICKS_COMPANY_THRESHOLD
              : LINK_CLICKS_CONTACT_THRESHOLD),
          label: getRelationshipSignalLabel(
            linkClicksDelta,
            type,
            contact,
            company,
          ),
        },
      ];
    case "revisits":
      const revisitsDelta =
        +row["fact_table.revisits_current"] - +row["fact_table.revisits_prev"];
      if (
        revisitsDelta <=
        (!!company ? REVISITS_COMPANY_THRESHOLD : REVISITS_CONTACT_THRESHOLD)
      ) {
        return [];
      }
      return [
        {
          positive: true,
          label: getRelationshipSignalLabel(
            revisitsDelta,
            type,
            contact,
            company,
          ),
        },
      ];
    case "active-contacts":
      const { actives, inactives, dormants } = getActivityTypeCount(
        "current",
        result,
      );
      const total = actives + inactives + dormants;
      const activeContactsRatio = total > 0 ? actives / total : 0;
      const inactiveContactsRatio = total > 0 ? inactives / total : 0;
      if (activeContactsRatio <= 0.5 && inactiveContactsRatio <= 0.5) {
        return [];
      }
      return [
        {
          positive: activeContactsRatio > 0.5,
          label: getRelationshipSignalLabel(
            activeContactsRatio,
            type,
            contact,
            company,
          ),
        },
      ];
    case "active-contacts-change":
      const { actives: currentActives } = getActivityTypeCount(
        "current",
        result,
      );
      const { actives: prevActives } = getActivityTypeCount("prev", result);

      const activesDelta = currentActives - prevActives;
      if (activesDelta === 0) {
        return [];
      }
      return [
        {
          positive: activesDelta > 0,
          label: getRelationshipSignalLabel(
            activesDelta,
            type,
            contact,
            company,
          ),
        },
      ];
    case "new-contacts":
      const newContacts = +row["dim_contact.count"];
      if (newContacts === 0) {
        return [];
      }
      return [
        {
          positive: true,
          label: getRelationshipSignalLabel(
            newContacts,
            type,
            contact,
            company,
          ),
        },
      ];
  }
};

const getActivityTypeCount = (
  type: "current" | "prev",
  result?: { [key: string]: string | number | boolean | undefined }[],
): { [key: string]: number } => {
  if (!result) {
    return {
      actives: 0,
      inactives: 0,
      dormants: 0,
    };
  }
  const statusKey =
    type === "prev"
      ? "dim_contact.activity_prev_status"
      : "dim_contact.activity_status";
  const actives = +(
    result.find((r) => +(r[statusKey] as string) === ActivityType.Active)?.[
      "dim_contact.count"
    ] || 0
  );
  const inactives = +(
    result.find((r) => +(r[statusKey] as string) === ActivityType.Inactive)?.[
      "dim_contact.count"
    ] || 0
  );
  const dormants = +(
    result.find((r) => +(r[statusKey] as string) === ActivityType.Dormant)?.[
      "dim_contact.count"
    ] || 0
  );
  return {
    actives,
    inactives,
    dormants,
  };
};

const useRelationshipSignalQuery = ({
  type,
  query,
  contact,
  company,
  moreResults,
}: {
  type: RelationshipSignalType;
  query: Query;
  contact?: IContactDetail;
  company?: ICompanyDetail;
  moreResults?: { [key: string]: string | number | boolean }[];
}) => {
  const marker = useCubejsApi(`relationship-signals-${type}`);
  const { resultSet, isLoading } = useCubeQuery(query, {
    cubeApi: marker,
  });

  if (!company && !contact) {
    return { result: [], isLoading: false, rawResult: [] };
  }

  const result = applyRelationshipSignalLogic(
    type,
    [...(resultSet?.tablePivot() || []), ...(moreResults || [])],
    contact,
    company,
  );
  return { result, isLoading, rawResult: resultSet?.tablePivot() || [] };
};
export default useRelationshipSignalQuery;
