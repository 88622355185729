import { rest } from "msw";
import watchlist from "./responses/watchlist.json";
import search from "./responses/search.json";
import profile from "./responses/profile.json";
import team from "./responses/team.json";
import demoTags from "./responses/demo-tags.json";

import contact from "./responses/contact.json";
import {
  fakeCubeToken,
  fakeInteractions,
  fakeInteractionsStats,
  fakeMessages,
  fakeBarInsight,
  fakeMailboxes,
  fakeMessage,
  fakePieInsight,
  fakeLinks,
  fakeRelationshipSignal,
  fakeInteraction,
} from "./factories";
import { RelationshipSignalType } from "components/RelationshipSignals/useRelationshipSignals";

// cube api mocks
import watchlistContactKPI from "./responses/cube/watchlistContactKPI.json";
import watchlistCompanyKPI from "./responses/cube/watchlistCompanyKPI.json";
import { faker } from "@faker-js/faker";
import { isFuture } from "date-fns";
import fakeSentiment from "./factories/sentiment";

export const handlers = [
  rest.get("/api/user/profile", (req, res, ctx) => res(ctx.json(profile))),
  rest.get("/api/pbi/user/me/team", (req, res, ctx) => res(ctx.json(team))),
  rest.get("/api/pbi/search", (req, res, ctx) => {
    const text = req.url.searchParams.get("text");
    if (!text || text === "") return res(ctx.json([]));
    return res(ctx.json(search));
  }),
  rest.get("/api/pbi/watchlist", (req, res, ctx) => res(ctx.json(watchlist))),
  rest.get("/api/track/interactions/stats", (req, res, ctx) =>
    res(ctx.json(fakeInteractionsStats())),
  ),
  rest.get("/api/track/interactions/count?*", (req, res, ctx) =>
    res(ctx.json({ count: faker.datatype.number({ min: 0, max: 1 }) })),
  ),
  rest.get("/api/track/interactions*", (req, res, ctx) => {
    if (req.url.href.includes("happened_after")) {
      const isHappeningInFuture = isFuture(
        new Date(req.url.searchParams.get("happened_after") as string),
      );
      return res(
        ctx.json({
          next: isHappeningInFuture
            ? undefined
            : faker.helpers.maybe(() => "fakeurl"),
          results: isHappeningInFuture
            ? []
            : faker.helpers.maybe(() => [
                fakeInteraction({
                  messageSubject: "New Message" + new Date().getSeconds(),
                  actionCreationTimestamp: new Date().toISOString(),
                }),
              ]) || [],
        }),
      );
    } else {
      return res(ctx.json(fakeInteractions()));
    }
  }),
  rest.get("/api/track/mailboxes", (req, res, ctx) =>
    res(ctx.json(fakeMailboxes({}))),
  ),
  rest.get("/api/track/emails*", (req, res, ctx) => {
    // generate a new email
    if (req.url.href.includes("happened_after")) {
      const isHappeningInFuture = isFuture(
        new Date(req.url.searchParams.get("happened_after") as string),
      );
      return res(
        ctx.json({
          next: isHappeningInFuture
            ? undefined
            : faker.helpers.maybe(() => "hello", {
                probability: 0.2,
              }),
          results: isHappeningInFuture
            ? []
            : faker.helpers.maybe(
                () => [
                  fakeMessage({
                    override: {
                      messageSubject: "New Message" + new Date().getSeconds(),
                      sendTimestamp: new Date().toISOString(),
                    },
                  }),
                ],
                { probability: 0.5 },
              ) || [],
        }),
      );
    } else {
      return res(ctx.json(fakeMessages()));
    }
  }),
  rest.get("/api/track/link-tracking*", (req, res, ctx) =>
    res(ctx.json(fakeLinks())),
  ),
  rest.get("/api/track/email/*", (req, res, ctx) => {
    if (req.url.href.includes("time-series-breakdown?granularity=day")) {
      return res(ctx.json(fakeBarInsight("day")));
    }
    if (req.url.href.includes("time-series-breakdown?granularity=hour")) {
      return res(ctx.json(fakeBarInsight("hour")));
    }
    if (req.url.href.includes("engagement-breakdown")) {
      return res(ctx.json(fakePieInsight("engagement-breakdown")));
    }
    if (req.url.href.includes("interaction-by-hour-breakdown")) {
      return res(ctx.json(fakePieInsight("interaction-by-hour-breakdown")));
    }
    if (req.url.href.includes("interaction-by-device-breakdown")) {
      return res(ctx.json(fakePieInsight("interaction-by-device-breakdown")));
    }
    if (req.url.href.includes("interaction-per-contact-breakdown")) {
      return res(ctx.json(fakePieInsight("interaction-per-contact-breakdown")));
    }
    if (req.url.href.includes("recipient-by-country-breakdown")) {
      return res(ctx.json(fakePieInsight("recipient-by-country-breakdown")));
    }

    return res(ctx.json(fakeMessage({ options: { isDetail: true } })));
  }),
  rest.get("/api/demo_tag", (req, res, ctx) => res(ctx.json(demoTags))),
  rest.get("/api/pbi/contact/*", (req, res, ctx) => res(ctx.json(contact))),
  rest.get("/api/pbi/company/*", (req, res, ctx) =>
    res(ctx.json(contact.company)),
  ),
  rest.post("/api/cube/token", (req, res, ctx) =>
    res(ctx.json(fakeCubeToken({}))),
  ),
  rest.get("/api/fake-cubeapi/dev/load", (req, res, ctx) => {
    const qp = req.url.searchParams.get("query");
    if (!qp || qp === "") return res(ctx.json({}));
    const cubeParams = JSON.parse(qp);
    const marker = req.headers.get("marker") || "";

    // use custom header "marker" to setup mocks in cubedev API response
    if (
      marker === "watchlist" &&
      cubeParams.dimensions.includes("dim_contact.uuid")
    ) {
      return res(ctx.json(watchlistContactKPI));
    }
    if (
      marker === "watchlist" &&
      cubeParams.dimensions.includes("dim_company.uuid")
    ) {
      return res(ctx.json(watchlistCompanyKPI));
    }

    if (marker === "sentiment") {
      return res(ctx.json(fakeSentiment()));
    }

    if (marker.startsWith("relationship-signals-")) {
      return res(
        ctx.json(
          fakeRelationshipSignal(
            marker.replace(
              "relationship-signals-",
              "",
            ) as RelationshipSignalType,
          ),
        ),
      );
    }

    return res(ctx.json({}));
  }),
];
