import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router";
import { AnalyticsProvider } from "use-analytics";
import { PersistGate } from "redux-persist/integration/react";

import store, { persistor } from "./app/store";
import { isMockedAPI } from "config";
import reportWebVitals from "./reportWebVitals";

import "./index.css";
import "react-tooltip/dist/react-tooltip.css";

import router from "./router";
import analytics from "./analytics";

// Start the mocking conditionally.
if (isMockedAPI()) {
  const { worker } = require("./mocks/browser");
  worker.start();
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    {/* @ts-ignore */}
    <AnalyticsProvider instance={analytics}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RouterProvider router={router} />
        </PersistGate>
      </Provider>
    </AnalyticsProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
