import classnames from "classnames";
import { complement, descend, filter, has, prop, sort } from "ramda";
import { format } from "date-fns";
import { useSelector } from "react-redux";

import TooltipFrame from "components/frames/TooltipFrame";
import { useGetUserQuery } from "features/profile/profileApi";
import useEntityActivity from "features/entity/useEntityActivity";
import { selectCubeFilters } from "app/filtersSlice";
import { ReactElement } from "react";

type ActivityItemColor = "green" | "blue" | "gray";
type ActivityItemType = {
  date?: Date;
  type?: ActivityItemColor;
  content?: ReactElement | string;
};

const ActivityItem = ({ date, type, content }: ActivityItemType) => {
  const formatDate = date ? format(date, "LLL do") : "";
  return (
    <div className="flex items-center gap-2">
      <div className="w-12 text-right text-metal">{formatDate}</div>
      <div
        className={classnames("h-8 w-1.5 rounded", {
          "bg-mint": type === "green",
          "bg-sky": type === "blue",
          "bg-dust-dark": type === "gray",
        })}
      ></div>
      <div className="text-metal">{content}</div>
    </div>
  );
};

const SkeletonActivityItem = () => (
  <div className="flex animate-pulse items-center gap-2">
    <div className="h-6 w-12 bg-dust-light"></div>
    <div className="h-8 w-1.5 rounded bg-dust-light"></div>
    <div className="h-3 w-[240px] rounded bg-dust-light"></div>
  </div>
);

const ActivityTimeline = ({
  type,
  name,
  id,
}: {
  type: "contact" | "company";
  name: string;
  id: string;
}) => {
  const { data: user } = useGetUserQuery();
  const cubeFilters = useSelector(selectCubeFilters);
  const { viewer, globalInteraction, globalMeeting, isLoading } =
    useEntityActivity({
      [type === "contact" ? "contactId" : "companyId"]: id,
      additionalFilters: cubeFilters,
      user,
    });

  const lastGlobalInteractionDate = prop("date", globalInteraction);
  const lastGlobalMeetingDate = prop("date", globalMeeting);

  const activities: ActivityItemType[] = [];

  if (!viewer.lastOutbound) {
    activities.push({
      content: `You've never sent an email to ${name}`,
      type: "gray",
    });
  } else {
    activities.push({
      content: `Your last email to ${name}`,
      date: new Date(viewer.lastOutbound as string),
      type: "blue",
    });
  }
  if (!viewer.lastInbound) {
    activities.push({
      content: `You've never received an email from ${name}`,
      type: "gray",
    });
  } else {
    activities.push({
      content: `Your last email from ${name}`,
      date: new Date(viewer.lastInbound as string),
      type: "blue",
    });
  }
  if (!viewer.lastMeeting) {
    activities.push({
      content: `You've never had a meeting with ${name}`,
      type: "gray",
    });
  } else {
    activities.push({
      content: `Your last meeting with ${name}`,
      date: new Date(viewer.lastMeeting as string),
      type: "blue",
    });
  }

  // global interactions
  if (lastGlobalInteractionDate) {
    activities.push({
      content: (
        <>
          <span className="font-bold text-navy">
            {prop("colleague", globalInteraction)}
          </span>{" "}
          emailed {name}
        </>
      ),
      date: new Date(lastGlobalInteractionDate as string),
      type: "green",
    });
  } else {
    activities.push({
      content: `No other colleagues have interacted with ${name}`,
      type: "gray",
    });
  }

  // global meetings
  if (lastGlobalMeetingDate) {
    activities.push({
      content: (
        <>
          <span className="font-bold text-navy">
            {prop("colleague", globalMeeting)}
          </span>{" "}
          had a meeting with {name}
        </>
      ),
      date: new Date(lastGlobalMeetingDate as string),
      type: "green",
    });
  } else {
    activities.push({
      content: `No other colleagues have had a meeting with ${name}`,
      type: "gray",
    });
  }

  const sorted = [
    ...sort(descend<any>(prop("date")), filter(has("date"), activities)),
    ...filter(complement(has("date")), activities),
  ] as ActivityItemType[];

  return (
    <TooltipFrame title="Most recent activity">
      {isLoading ? (
        <>
          {Array(5)
            .fill(null)
            .map((_, i) => (
              <SkeletonActivityItem key={i} />
            ))}
        </>
      ) : (
        sorted.map((activity, index) => (
          <ActivityItem
            type={activity.type as ActivityItemColor}
            date={activity.date}
            content={activity.content}
            key={index}
          />
        ))
      )}
    </TooltipFrame>
  );
};

export default ActivityTimeline;
