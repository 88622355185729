import { useCubeQuery } from "@cubejs-client/react";
import { useGetUserQuery } from "features/profile/profileApi";
import { useCubejsApi } from "utils/hooks/useCubejsApi";

const useMeetingsBreakdown = () => {
  const cubeApi = useCubejsApi("meetings-breakdown");
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();
  const { resultSet, isLoading: isReportLoading } = useCubeQuery(
    {
      measures: ["dim_calendar.count"],
      order: [],
      dimensions: [],
      filters: [
        {
          member: "dim_user.email",
          operator: "equals",
          values: [user ? (user.email as string) : ""],
        },
      ],
      timeDimensions: [
        {
          dimension: "dim_calendar.start",
          granularity: "day",
          dateRange: "Last 90 days",
        },
      ],
    },
    {
      cubeApi,
    },
  );
  const results = (resultSet?.tablePivot() || []) as any[];
  return {
    resultSet: results,
    isLoading: isReportLoading || isUserLoading,
  };
};

export default useMeetingsBreakdown;
