import { useEffect, useMemo, useState } from "react";
import { concat, map, prop, propOr, props, sortBy, uniqBy } from "ramda";
import classNames from "classnames";
import Xarrow from "react-xarrows";

import RelataLoading from "assets/RelataLoading.svg";

import {
  ActivityType,
  IPhoneNumber,
  TrendType,
} from "features/entity/entity.interfaces";
import { InteractionCell, ProgressBarCell } from "components/table/Cells";
import { activityColors, trendColors } from "utils/colorMapping";
import Button from "components/Button";
import EntityIdentifier from "components/EntityIdentifier";
import { formatDifferenceFromNow } from "utils/dates";

const RelationshipItem = ({
  direction,
  id,
  name,
  meta,
  inactive,
  description,
  relationshipScore,
  lastInteraction,
  trend,
  activity,
  selectedId,
  showDropdown,
  query,
  onExpand,
  onSelect,
}: {
  direction: "to" | "from";
  id: string;
  name: string;
  meta?: { email?: string; phoneNumbers?: IPhoneNumber[] };
  inactive: boolean;
  description: string;
  relationshipScore: any;
  lastInteraction: string;
  trend: string;
  activity: string;
  selectedId?: string;
  showDropdown: boolean;
  query: string;
  onExpand: () => void;
  onSelect: (value: any) => void;
}) => {
  const [opened, setOpened] = useState(false);
  const trendColor: "green" | "orange" = propOr("green", trend, trendColors);
  return (
    <div
      className={classNames(
        "flex flex-col gap-4 rounded-lg border border-dust-dark p-3 shadow",
        {
          "border-mint": selectedId === id,
          "opacity-20": (selectedId && selectedId !== id) || inactive,
          "cursor-pointer hover:border-dust-darker hover:shadow-lg": !inactive,
        },
      )}
      id={`${direction}-${id}`}
      onClick={() => onSelect(selectedId === id ? undefined : id)}
    >
      <div className="flex h-8 items-center justify-between gap-2">
        <EntityIdentifier
          id={id}
          title={name}
          meta={meta}
          searchValue={query}
          type={direction === "from" ? "colleague" : "contact"}
        />
        {(!showDropdown || inactive) && (
          <div className="ml-auto text-sm text-metal">{description}</div>
        )}
        {showDropdown && !inactive && (
          <Button
            color="transparent"
            icon={opened ? "icon-up" : "icon-down"}
            onClick={(event) => {
              event.stopPropagation();
              onExpand();
              setOpened(!opened);
            }}
          />
        )}
      </div>
      {showDropdown && opened && !inactive && (
        <div className="flex justify-between">
          <div className="-ml-6 flex flex-col items-start gap-1">
            <div className="px-6 text-metal">Relationship score</div>
            <ProgressBarCell
              value={relationshipScore}
              description={TrendType[+trend]}
              color={trendColor}
            />
          </div>
          <div className="flex flex-col items-start gap-1">
            <div className="text-metal">Last interaction</div>
            <InteractionCell
              who=""
              when={formatDifferenceFromNow(new Date(lastInteraction))}
              tagLabel={ActivityType[+activity]}
              tagColor={propOr("green", activity, activityColors)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

type RelationshipExplorerDataType = {
  from: string;
  to: string;
  flow: number;
  fromId: string;
  toId: string;
  activity: string;
  trend: string;
  relationshipScore: any;
  lastInteraction: string;
  contactEmail: string;
  contactPhoneNumbers: IPhoneNumber[];
  index: number;
};

const RelationshipExplorerChart = ({
  data,
  isLoading,
  contactQuery,
  colleagueQuery,
}: {
  data: RelationshipExplorerDataType[];
  isLoading: boolean;
  contactQuery: string;
  colleagueQuery: string;
}) => {
  const [selectedCollague, setSelectedColleague] = useState();
  const [selectedContact, setSelectedContact] = useState();

  const [state, updateState] = useState(0);
  const forceUpdate = () => updateState(state + 1);

  const relationshipMap: { [key: string]: string[] } = useMemo(
    () =>
      map(props(["fromId", "toId"]), data).reduce(
        (obj, [from, to]) => ({
          ...obj,
          [from]: concat(propOr([], from, obj), [to]),
          [to]: concat(propOr([], to, obj), [from]),
        }),
        {},
      ),
    [data],
  );

  // prevents keeping selected contacts and colleagues when search is applied
  useEffect(() => {
    if (selectedCollague && !(selectedCollague in relationshipMap)) {
      setSelectedColleague(undefined);
    }
    if (selectedContact && !(selectedContact in relationshipMap)) {
      setSelectedContact(undefined);
    }
  }, [selectedCollague, selectedContact, relationshipMap]);

  const colleagues = useMemo(
    () =>
      uniqBy(
        prop("fromId"),
        sortBy((contact: RelationshipExplorerDataType) => {
          if (selectedContact && contact.toId === selectedContact) {
            return 0;
          }
          return 1;
        }, data),
      ),
    [data, selectedContact],
  ) as RelationshipExplorerDataType[];

  const contacts = useMemo(
    () =>
      uniqBy(
        prop("toId"),
        sortBy((colleague: RelationshipExplorerDataType) => {
          if (selectedCollague && colleague.fromId === selectedCollague) {
            return 0;
          }
          return 1;
        }, data),
      ),
    [data, selectedCollague],
  ) as RelationshipExplorerDataType[];

  const getColor = ({
    fromId,
    toId,
    activity,
  }: {
    fromId: string;
    toId: string;
    activity: string;
  }) => {
    if (
      (selectedCollague && selectedCollague !== fromId) ||
      (selectedContact && selectedContact !== toId)
    ) {
      return "#F0F8FF";
    }
    if (+activity === ActivityType.Active) {
      return "#8BE5C6";
    } else if (+activity === ActivityType.Inactive) {
      return "#FF9271";
    } else if (+activity === ActivityType.Dormant) {
      return "#F5505D";
    }
  };

  const getDescription = (id: any) => {
    const relationshipLength = relationshipMap[id].length;
    return `${relationshipLength} ${
      relationshipLength > 1 ? "relationships" : "relationship"
    }`;
  };

  const isInactive = (root: string | undefined, node: string) => {
    if (root === undefined) {
      return false;
    }
    const children = relationshipMap[root];
    if (children === undefined) {
      return false;
    } else {
      return !relationshipMap[root].includes(node);
    }
  };

  // loading state
  if (isLoading) {
    return (
      <div className="my-[120px] flex items-center justify-center">
        <div className="flex flex-col items-center">
          <img
            src={RelataLoading}
            className="h-[96px] w-[96px]"
            alt="loading spinner"
          />
          <div className="mt-6 text-xl font-bold text-navy">
            Loading relationship explorer
          </div>
        </div>
      </div>
    );
  }

  // empty state
  if (data.length === 0) {
    return (
      <div className="my-[120px] flex items-center justify-center">
        <div className="flex flex-col text-center">
          <i className="icon-relationships text-3xl text-navy"></i>
          <div className="mt-2 text-xl font-bold text-navy">
            No relationships found
          </div>
          <div className="mt-2 w-[300px] text-metal">
            Check if you have any filters applied, or try searching for a
            different contact or colleague.
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="flex justify-between">
        <div className="my-5 flex flex-col gap-2 sm:w-[400px]">
          {colleagues.map(
            ({
              fromId,
              from,
              relationshipScore,
              trend,
              activity,
              lastInteraction,
            }) => (
              <RelationshipItem
                direction="from"
                id={fromId}
                name={from}
                description={getDescription(fromId)}
                onExpand={forceUpdate}
                selectedId={selectedCollague}
                showDropdown={!!selectedContact}
                inactive={isInactive(selectedContact, fromId)}
                relationshipScore={relationshipScore}
                lastInteraction={lastInteraction}
                trend={trend}
                activity={activity}
                key={fromId}
                query={colleagueQuery}
                onSelect={(value) => {
                  setSelectedColleague(value);
                  setSelectedContact(undefined);
                }}
              />
            ),
          )}
        </div>
        <div className="my-5 flex flex-col gap-2 sm:w-[400px]">
          {contacts.map(
            ({
              toId,
              to,
              relationshipScore,
              trend,
              activity,
              lastInteraction,
              contactEmail,
              contactPhoneNumbers,
            }) => (
              <RelationshipItem
                direction="to"
                id={toId}
                name={to}
                description={getDescription(toId)}
                meta={{
                  email: contactEmail,
                  phoneNumbers: contactPhoneNumbers,
                }}
                selectedId={selectedContact}
                showDropdown={!!selectedCollague}
                key={toId}
                query={contactQuery}
                onExpand={forceUpdate}
                onSelect={(value) => {
                  setSelectedColleague(undefined);
                  setSelectedContact(value);
                }}
                relationshipScore={relationshipScore}
                lastInteraction={lastInteraction}
                trend={trend}
                activity={activity}
                inactive={isInactive(selectedCollague, toId)}
              />
            ),
          )}
        </div>
      </div>
      {data.map(({ fromId, toId, activity, index }) => (
        <Xarrow
          key={`${fromId}-${toId}-${state}-${selectedCollague}-${selectedContact}`}
          start={`from-${fromId}`}
          end={`to-${toId}`}
          showHead={false}
          color={getColor({ fromId, toId, activity })}
          strokeWidth={
            +activity !== ActivityType.Dormant ? (index < 3 ? 4 : 2) : 1
          }
          dashness={+activity === ActivityType.Inactive}
          zIndex={
            selectedCollague === fromId || selectedContact === toId ? 2 : 1
          }
          headSize={state}
        />
      ))}
    </>
  );
};

export default RelationshipExplorerChart;
