import { Permissions } from "features/profile";
import { useGetUserQuery } from "features/profile/profileApi";
import { ReactElement } from "react";

const PermissionGuard = ({
  permission,
  children,
  denied,
}: {
  permission: Permissions;
  children: ReactElement;
  denied?: ReactElement;
}): ReactElement => {
  const { data: user } = useGetUserQuery();
  if (user?.permissions.includes(permission)) {
    return children;
  }
  return denied || <></>;
};
export default PermissionGuard;
