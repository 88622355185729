import { useCubeQuery } from "@cubejs-client/react";
import { Filter, Query } from "@cubejs-client/core";
import { useGetUserQuery } from "features/profile/profileApi";
import { useCubejsApi } from "utils/hooks/useCubejsApi";
import { find, propEq } from "ramda";

const useClientServiceLevelQuery = ({
  query,
  skip = false,
}: {
  query: Query;
  skip?: boolean;
}) => {
  const { resultSet, isLoading, error } = useCubeQuery(query as Query, {
    skip,
    cubeApi: useCubejsApi("client-service-level"),
  });

  if (error) {
    console.error(error);
  }

  return {
    results: resultSet,
    isLoading,
  };
};

const useClientServiceLevelCompanies = ({
  timeOption,
  teamOption,
  lensOption,
  limit,
  offset,
}: {
  timeOption: string;
  teamOption: string;
  lensOption: string;
  limit: number;
  offset: number;
}) => {
  const { data: user } = useGetUserQuery();
  const entityFilters = (): Filter[] => {
    return [
      {
        member: "dim_company.uuid",
        operator: "set",
      },
      {
        member: "dim_company.is_service_provider",
        operator: "equals",
        values: ["false"],
      },
      {
        member: "dim_contact.is_leaver",
        operator: "equals",
        values: ["false"],
      },
    ];
  };

  const additionalFilters = (): Filter[] => {
    const ret = [];
    if (teamOption !== "all-teams") {
      ret.push({
        member: "dim_team.uuid",
        operator: "equals",
        values: [teamOption],
      } as Filter);
    }
    if (lensOption !== "top-companies") {
      ret.push({
        member: "dim_lenses.lenses",
        operator: "contains",
        values: [`|${lensOption}|`],
      } as Filter);
    }
    return ret;
  };

  const dateRange = timeOption || "Last 180 days";
  const baseQuery: Query = {
    dimensions: [
      "dim_company.display_name",
      "dim_company.uuid",
      "dim_company.logo_url",
    ],
    measures: [],
    filters: [...entityFilters(), ...additionalFilters()],
    timeDimensions: [
      {
        dimension: "dim_date.date_actual",
        dateRange,
      },
    ],
    limit,
    offset,
    total: true,
  };

  const {
    results: meetingsServiceLevel,
    isLoading: isMeetingsServiceLevelLoading,
  } = useClientServiceLevelQuery({
    query: {
      ...baseQuery,
      measures: ["dim_calendar.count", "dim_calendar.duration"],
    },
    skip: !user,
  });

  const {
    results: responseTimesServiceLevel,
    isLoading: isResponseTimesServiceLevelLoading,
  } = useClientServiceLevelQuery({
    query: {
      ...baseQuery,
      measures: [
        "fact_email.avg_reply_response_time",
        "fact_email.avg_reply_out_response_time",
        "fact_email.outbound",
        "fact_email.outbound_engaged",
        "fact_email.inbound",
      ],
    },
    skip: !user,
  });

  const {
    results: contactsServiceLevel,
    isLoading: isContactsServiceLevelLoading,
  } = useClientServiceLevelQuery({
    query: {
      ...baseQuery,
      measures: [
        "dim_contact.count",
        "fact_relationship_score.relationship_score",
      ],
      order: { "fact_relationship_score.relationship_score": "desc" },
    },
    skip: !user,
  });

  const {
    results: newContactsServiceLevel,
    isLoading: isNewContactsServiceLevelLoading,
  } = useClientServiceLevelQuery({
    query: {
      ...baseQuery,
      filters: [
        ...(baseQuery.filters as Filter[]),
        {
          member: "dim_contact.segment_status",
          operator: "equals",
          values: ["0"],
        },
      ],
      measures: [
        "dim_contact.count",
        "fact_relationship_score.relationship_score",
      ],
    },
    skip: !user,
  });

  const {
    results: activeContactsServiceLevel,
    isLoading: isActiveContactsServiceLevelLoading,
  } = useClientServiceLevelQuery({
    query: {
      ...baseQuery,
      filters: [
        ...(baseQuery.filters as Filter[]),
        {
          member: "dim_contact.activity_status",
          operator: "equals",
          values: ["0"],
        },
      ],
      measures: [
        "dim_contact.count",
        "fact_relationship_score.relationship_score",
      ],
    },
    skip: !user,
  });

  const meetings = (meetingsServiceLevel?.tablePivot() || []) as any[];
  const responseTimes = (responseTimesServiceLevel?.tablePivot() ||
    []) as any[];
  const contacts = (contactsServiceLevel?.tablePivot() || []) as any[];
  const newContacts = (newContactsServiceLevel?.tablePivot() || []) as any[];
  const activeContacts = (activeContactsServiceLevel?.tablePivot() ||
    []) as any[];

  const results = contacts.map((company: any) => {
    const company_uuid = company["dim_company.uuid"];
    const meeting = find(
      propEq("dim_company.uuid", company_uuid),
      meetings,
    ) as any;
    const responseTime = find(
      propEq("dim_company.uuid", company_uuid),
      responseTimes,
    ) as any;
    const activeContact = find(
      propEq("dim_company.uuid", company_uuid),
      activeContacts,
    ) as any;
    const newContact = find(
      propEq("dim_company.uuid", company_uuid),
      newContacts,
    ) as any;

    return {
      "dim_company.uuid": company_uuid,
      "dim_company.display_name": company["dim_company.display_name"],
      "dim_company.logo_url": company["dim_company.logo_url"],
      "dim_contact.count": +company["dim_contact.count"],
      "fact_table.relationship_score_current":
        +company["fact_relationship_score.relationship_score"],
      "active_contact.count": !!activeContact
        ? +activeContact["dim_contact.count"]
        : 0,
      "new_contact.count": !!newContact ? +newContact["dim_contact.count"] : 0,
      "dim_calendar.count": !!meeting ? +meeting["dim_calendar.count"] : 0,
      "dim_calendar.duration": !!meeting
        ? +meeting["dim_calendar.duration"]
        : 0,
      "fact_email.avg_reply_response_time": !!responseTime
        ? +responseTime["fact_email.avg_reply_response_time"]
        : 0,
      "fact_email.avg_reply_out_response_time": !!responseTime
        ? +responseTime["fact_email.avg_reply_out_response_time"]
        : 0,
      "fact_email.inbound": !!responseTime
        ? +responseTime["fact_email.inbound"]
        : 0,
      "fact_email.outbound": !!responseTime
        ? +responseTime["fact_email.outbound"]
        : 0,
      "fact_email.outbound_engaged": !!responseTime
        ? +responseTime["fact_email.outbound_engaged"]
        : 0,
    };
  });

  // @ts-ignore
  const total = contactsServiceLevel?.loadResponse.results[0].total;

  return {
    resultSet: { results, total },
    isLoading:
      isMeetingsServiceLevelLoading ||
      isResponseTimesServiceLevelLoading ||
      isContactsServiceLevelLoading ||
      isNewContactsServiceLevelLoading ||
      isActiveContactsServiceLevelLoading,
  };
};

export default useClientServiceLevelCompanies;
