import { useCubeQuery } from "@cubejs-client/react";
import { format, subDays } from "date-fns";
import {
  descend,
  filter,
  fromPairs,
  pluck,
  prop,
  propSatisfies,
  sort,
  uniq,
  values,
} from "ramda";

import { useGetUserQuery } from "features/profile/profileApi";
import { useCubejsApi } from "utils/hooks/useCubejsApi";

const mergeData = (rows: any[]) => {
  const ids = uniq(pluck("dim_contact.uuid", rows));
  const results = fromPairs(
    ids.map((id) => [
      id,
      {
        "dim_contact.uuid": id,
        "dim_contact.full_name": "",
        "dim_company.display_name": "",
        "dim_contact.email": "",
        emails: 0,
        revisits: 0,
      },
    ]),
  );
  rows.forEach((row) => {
    const acc = results[row["dim_contact.uuid"]];
    results[row["dim_contact.uuid"]] = {
      ...row,
      emails: acc.emails + 1,
      revisits: acc.revisits + row["fact_email.revisits"],
    };
  });
  return sort(
    descend<any>(prop("revisits")),
    filter(
      propSatisfies((x) => x > 4, "revisits"),
      values(results),
    ),
  );
};

const useFrequentRevisits = () => {
  const cubeApi = useCubejsApi("frequent-revisits");
  const afterDate = format(subDays(new Date(), 30), "yyyy-MM-dd");
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();
  const { resultSet, isLoading: isReportLoading } = useCubeQuery(
    {
      dimensions: [
        "dim_contact.uuid",
        "dim_contact.full_name",
        "dim_company.display_name",
        "fact_email.revisits",
        "dim_contact.email",
        ...(user?.showContactSignatures ? ["dim_contact.phone_numbers"] : []),
      ],
      filters: [
        {
          member: "fact_email.revisits",
          operator: "gte",
          values: ["1"],
        },
        {
          member: "dim_user.email",
          operator: "equals",
          values: [user ? (user.email as string) : ""],
        },
        {
          member: "dim_email.message_sent_date_time",
          operator: "afterDate",
          values: [afterDate],
        },
        {
          member: "dim_contact.is_leaver",
          operator: "equals",
          values: ["false"],
        },
      ],
    },
    { cubeApi },
  );
  const results = (resultSet?.tablePivot() || []) as any[];
  return {
    resultSet: mergeData(results),
    isLoading: isReportLoading || isUserLoading,
  };
};

export default useFrequentRevisits;
