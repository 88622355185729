import { useCubeQuery } from "@cubejs-client/react";
import { format, subDays } from "date-fns";
import { useGetUserQuery } from "features/profile/profileApi";
import { useCubejsApi } from "utils/hooks/useCubejsApi";

const useUnresponsiveContacts = () => {
  const cubeApi = useCubejsApi("unresponsive-contacts");
  const afterDate = format(subDays(new Date(), 90), "yyyy-MM-dd");
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();
  const { resultSet, isLoading: isReportLoading } = useCubeQuery(
    {
      measures: ["fact_table.outbound_current"],
      order: [
        ["fact_table.outbound_current", "desc"],
        ["dim_contact.full_name", "asc"],
      ],
      dimensions: [
        "dim_contact.uuid",
        "dim_contact.full_name",
        "dim_company.display_name",
        "dim_contact.email",
        ...(user?.showContactSignatures ? ["dim_contact.phone_numbers"] : []),
      ],
      filters: [
        {
          member: "fact_table.outbound_current",
          operator: "gt",
          values: ["0"],
        },
        {
          member: "fact_table.days_since_last_engagement",
          operator: "gte",
          values: ["90"],
        },
        {
          member: "dim_contact.is_leaver",
          operator: "equals",
          values: ["false"],
        },
        {
          member: "dim_user.email",
          operator: "equals",
          values: [user ? (user.email as string) : ""],
        },
        {
          member: "dim_email.message_sent_date_time",
          operator: "afterDate",
          values: [afterDate],
        },
      ],
    },
    {
      cubeApi,
    },
  );
  const results = (resultSet?.tablePivot() || []) as any[];
  return {
    resultSet: results,
    isLoading: isReportLoading || isUserLoading,
  };
};

export default useUnresponsiveContacts;
